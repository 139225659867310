import React, { useState, useEffect, useRef } from 'react'
import {
    HeroNSection,
    HeroNContainer,
    HeroNContent,
    WindowWrapper,
    PrevBtnWrapper,
    Prev,
    NextBtnWrapper,
    Next,
    DotsWrapper,
    Dot,
    ImageSlider,
    Content,
    TextQuote,
    Author
} from './HeroNE'
import { ImagesSliderData } from './ImgData'
import { gsap } from 'gsap'

const HeroN = () => {
    const [activeImg, setActiveImg] = useState(0)
    const sliderImg = useRef(null);

    const handleNext = () => {
        if(activeImg === ImagesSliderData.length - 1){
            setActiveImg(0)
        } else {
            setActiveImg(activeImg + 1)
        }
    }

    const handlePrev = () => {
        if(activeImg === 0){
            setActiveImg(ImagesSliderData.length - 1)
        } else {
            setActiveImg(activeImg - 1)
        }
    }

    useEffect(() => {
        const si = sliderImg.current;

        gsap.fromTo(si, {opacity: 0}, {opacity: 1})

        const interval = setInterval(() => {
            setActiveImg(activeImg === ImagesSliderData.length - 1 ? 0 : activeImg + 1)
        }, 7000);
        return () => clearInterval(interval);
    }, [activeImg]);

    return (
        <HeroNSection>
            <HeroNContainer>
                <HeroNContent>
                    <WindowWrapper ref={sliderImg}>
                        <PrevBtnWrapper onClick={handlePrev}>
                            <Prev className="prevBtn"/>
                        </PrevBtnWrapper>
                        <NextBtnWrapper onClick={handleNext}>
                            <Next className="nextBtn"/>
                        </NextBtnWrapper>
                        <DotsWrapper>
                            {ImagesSliderData.map((obj, index) =>
                                    <Dot 
                                        key={index} 
                                        className={`${obj.id === activeImg ? 'active' : 'inactive'}`} 
                                        onClick={() => setActiveImg(index)}
                                    />
                            )}
                        </DotsWrapper>
                        <ImageSlider src={ImagesSliderData[activeImg].src} alt={ImagesSliderData[activeImg].alt}/>
                        <Content>
                            <TextQuote>„Zdjęcie może być chwilą życia uchwyconą na wieczność,</TextQuote>
                            <TextQuote>która nigdy nie przestanie na ciebie patrzeć.”</TextQuote>
                            <Author>– Brigitte Bardot</Author>
                        </Content>
                    </WindowWrapper>
                </HeroNContent>
            </HeroNContainer>
        </HeroNSection>
    )
}

export default HeroN
