import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Suspense, lazy } from 'react'
import Home from './pages'
import { Helmet } from 'react-helmet'

const Sluby = lazy(() => import('./pages/sluby'))
const Sklep = lazy(() => import('./pages/sklep'))
const Okolicznosciowe = lazy(() => import('./pages/okolicznosciowe'))
const Szkoly = lazy(() => import('./pages/szkoly'))
const Galeria = lazy(() => import('./pages/galeria'))
const Blog = lazy(() => import('./pages/blog'))

function App() {
  return (
    <BrowserRouter>
    <Helmet>
        <title>FotoGold Fotograf Kamerzysta</title>
        <meta name="description" content="FotoGold Fotograf Kamerzysta Marek Jastrzębski Fotografia ślubna - Urodziny - Komunie - Pozostałe uroczystości - Studio - Plenner" />
        <meta
          name="keywords"
          content="
          fotograf,
          fotograf ostrzeszów,
          kamerzysta,
          kamerzysta ostrzeszów,
          fotograf kamerzysta,
          fotograf kamerzysta ostrzeszów,
          fotograf ślubny,
          fotograf na wesele,
          kamerzysta na wesele,
          plenner,
          studio,
          fotograf urodziny,
          fotograf 18nastka,
          fotograf wielkopolska,
          fotograf dolnośląskie,
          fotograf śląskie,
          fotograf łódzkie,
          fotograf kamerzysta wielkopolska,
          fotograf kamerzysta dolnośląskie,
          fotograf kamerzysta śląskie,
          fotograf kamerzysta łódzkie,
          fotograf poznań,
          fotograf kalisz,
          fotograf ostrów wielkopolski
          fotograf kamerzysta poznań,
          fotograf kamerzysta kalisz,
          fotograf kamerzysta ostrów wielkopolski,
          fotogold,
          fotograf kamerzysta jastrzębski
          zdjęcie na dowód,
          zdjęcie do dokumentów,
          fotograf kamerzysta studniówka
          fotograf kamerzysta uroczystości,
          fotogadżety"
        />
      </Helmet>
      <Switch>
        <Route path='/' component={Home} exact />
        <Suspense fallback={<div>Ładowanie...</div>}>
          <Route path='/wesele' component={Sluby} />
          <Route path='/sklep' component={Sklep} />
          <Route path='/okolicznosciowe' component={Okolicznosciowe} />
          <Route path='/szkoly' component={Szkoly} />
          <Route path='/galeria' component={Galeria} />
          <Route path='/faq' component={Blog} />
        </Suspense>
      </Switch>
      
    </BrowserRouter>
  );
}

export default App;
