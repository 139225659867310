import React, { useState } from 'react'
import { DropdownItems } from './DropdownItems'
import { ItemsList, Item, RLink } from './DropdownE'

export const Dropdown = (props) => {
    const [click, setClick] = useState(false)

    const handleClick = () => setClick(!click)

    return (
        <>
            <ItemsList onClick={handleClick} click={click} onMouseEnter={() => props.changeStyle(true)} onMouseLeave={() => props.changeStyle(false)}> 
                {DropdownItems.map((item, index) => {
                    return (
                        <Item key={index}>
                            <RLink to={item.path} target="_top" onClick={() => setClick(false)}>
                                {item.title}
                            </RLink>
                        </Item>
                    )
                })}
            </ItemsList>
        </>
    )
}

export default Dropdown