import React, {useState, useEffect} from 'react'
import {
    NavSection,
    NavContainer,
    Logo,
    LogoImg,
    HamburgerWrap,
    HamburgerMenu,
    HamburgerLine1,
    HamburgerLine2,
    HamburgerLine3,
    NavMenu,
    NavItem,
    RLink,
    SLink,
    HLink,
    DropdownMenu
} from './NavE'
import Dropdown from './Dropdown'
import logo from '../../images/fotogold2.png'

const Nav = () => {
    const [open, setOpen] = useState(false)
    const [dropdown, setDropdown] = useState(false)
    const [scrollNav, setScrollNav] = useState(false)
    const [activeMenu, setActiveMenu] = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 70) {
            setScrollNav(true)
        } else { 
            setScrollNav(false)
        }
    }
    
    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])

    const onMouseEnter = () => {
        setDropdown(true);
    }

    const onMouseLeave = () => {
        setDropdown(false);
    }

    return (
        <NavSection scrollNav={scrollNav ? 1 : 0}>
            <NavContainer>
                <Logo to='/' target="_top">
                    <LogoImg src={logo} alt='FotoGold Jastrzebski Fotograf Kamerzysta Ostrzeszów Logo' width='90px' height='100%'/>
                    <span>FotoGold</span>
                </Logo>
                <HamburgerWrap onClick={() => setOpen(!open)}>
                    <HamburgerMenu>
                        <HamburgerLine1 open={open ? 1 : 0}/>
                        <HamburgerLine2 open={open ? 1 : 0}/>
                        <HamburgerLine3 open={open ? 1 : 0}/>
                    </HamburgerMenu>
                </HamburgerWrap>
                <NavMenu open={open ? 1 : 0}>
                    <NavItem onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                        <DropdownMenu activeMenu={activeMenu ? 1 : 0}>
                            Oferta <div />
                        </DropdownMenu>
                        {dropdown && <Dropdown changeStyle={activeMenu => setActiveMenu(activeMenu)}/>}
                    </NavItem>
                    <NavItem>
                        <RLink to='/galeria' target="_top">Galeria</RLink>
                    </NavItem>
                    <NavItem>
                        <RLink to='/faq' target="_top">FAQ</RLink>
                    </NavItem>
                    <NavItem>
                        { window.location.pathname === '/' 
                        ? 
                        <SLink to='kontakt' smooth={true} duration={500} spy='true' exact='true' offset={-58} onClick={() => setOpen(false)}>Kontakt</SLink> 
                        : 
                        <HLink smooth to='/#kontakt' duration={500} spy='true' exact='true' offset={-58}>Kontakt</HLink>}
                    </NavItem>
                </NavMenu>
            </NavContainer>
        </NavSection>
    )
}

export default Nav
