import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const ItemsList = styled.ul`
    width: 220px;
    position: absolute;
    top: 56px;
    text-align: start;
    list-style: none;
    display: ${({ click }) => click ? 'none' : 'block'};
    z-index: 10;
    box-shadow: 15px 15px 15px rgba(0,0,0,.6);

    @media screen and (max-width: 760px) {
        top: 0;
        left: 111px;
    }
`

export const Item = styled.li`
    background-color: #aca9bb;
`

export const RLink = styled(Link)`
    display: block;
    width: 100%;
    height: 100%;
    padding: 8px 16px;
    font-size: 1.6rem;
    text-decoration: none;
    font-weight: normal;
    color: #1C1A28;
    cursor: pointer;
    font-family: 'Amita', cursive;
    letter-spacing: 1px;

    &:hover {
        background-color: #787586;
        // color: #324865;
        border-left: 3px solid #1C1A28;
        font-weight: bold;
    }

    @media screen and (max-width: 960px) {
        font-size: 1.4rem;
    }

    @media screen and (max-width: 760px) {
        font-size: 1.3rem;
        padding: 1rem;
    }
`