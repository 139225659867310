import image_1 from '../../images/slider1.jpg'
import image_2 from '../../images/slider2.jpg'
import image_3 from '../../images/slider3.jpg'
import image_4 from '../../images/slider4.jpg'
import image_5 from '../../images/slider5.jpg'
import image_6 from '../../images/slider6.jpg'

export const ImagesSliderData = [
    {
        id: 0,
        src: image_1,
        alt: 'Fotograf Kamerzysta Ostrzeszów Jastrzębski'
    },
    {
        id: 1,
        src: image_2,
        alt: 'Fotograf Kamerzysta Ostrzeszów Jastrzębski'
    },
    {
        id: 2,
        src: image_3,
        alt: 'Fotograf Kamerzysta Ostrzeszów Jastrzębski'
    },
    {
        id: 3,
        src: image_4,
        alt: 'Fotograf Kamerzysta Ostrzeszów Jastrzębski'
    },
    {
        id: 4,
        src: image_5,
        alt: 'Fotograf Kamerzysta Ostrzeszów Jastrzębski'
    },
    {
        id: 5,
        src: image_6,
        alt: 'Fotograf Kamerzysta Ostrzeszów Jastrzębski'
    },
]