import React, { Suspense, lazy } from 'react'
import HeroN from '../components/HeroN';
import Nav from '../components/Nav';

const Address = lazy(() => import('../components/Address'))
const AboutN = lazy(() => import('../components/AboutN'))
const Quote1 = lazy(() => import('../components/Quote1'))
const Contact = lazy(() => import('../components/Contact'))
const Footer = lazy(() => import('../components/Footer'))
const Icon = lazy(() => import('../components/Icon'))
const InfoHP = lazy(() => import('../components/InfoHP'))

const Home = () => {

    return (
        <>
            <Nav />
            <HeroN />
            <Suspense fallback={<div>Wczytywanie...</div>}>
                <AboutN />
                <Quote1 />
                <InfoHP />
                <Icon />
                <Address />
                <Contact />
                <Footer />
            </Suspense>
        </>
    )
}

export default Home
