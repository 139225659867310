import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS} from 'react-scroll'
import { HashLink as LinkH } from 'react-router-hash-link'

export const NavSection = styled.section`
    background-color: ${({ scrollNav }) => (scrollNav ? "#1C1A28" : "rgba(0,0,0,.3)")};
    position: sticky;
    top: 0;
    z-index: 12;
    box-shadow: ${({ scrollNav }) => (scrollNav ? "3px 5px 7px rgba(0,0,0,.5)" : "none")};
    margin-top: -60px;
    transition: all 0.2s ease-in;
`

export const NavContainer = styled.div`
    min-height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`

export const Logo = styled(LinkR)`
    opacity: .9;
    cursor: pointer;
    text-decoration: none;
    margin-left: 30px;
    transition: all 0.2s ease-in;
    display: flex;
    align-items: center;

    @media screen and (max-width: 760px) {
        margin-left: 20px;
    }

    @media screen and (max-width: 480px) {
        margin-left: 10px;
    }

    span {
        font-size: 2.2rem;
        color: #f7ffff;
        font-family: 'Amita', cursive;
        letter-spacing: 1px;

        @media screen and (max-width: 480px) {
            font-size: 2rem;
        }
    }
`

export const LogoImg = styled.img`
    width: 90px;
`

export const HamburgerWrap = styled.div`
    display: none;    
    cursor: pointer;

    @media screen and (max-width: 760px) {
        display: flex;
        margin-right: 30px;
    }
`

export const HamburgerMenu = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 40px;
    transition: all .5s ease-in-out;
    cursor: pointer;
`

export const HamburgerLine1 = styled.div`
    width: 25px;
    height: 4px;
    background-color: #e6f4f1;
    border-radius: 5px;
    transition: all .5s ease-in-out;
    transform: ${({open}) => (open ? 'rotate(45deg) translateY(4px)' : 'translateY(-3px)')};
`

export const HamburgerLine2 = styled.div`
    width: 22px;
    height: 2px;
    border-radius: 5px;
    transition: all .5s ease-in-out;
    transform: ${({open}) => (open ? 'translateX(-20px)' : 'none')};
    background-color: ${({open}) => (open ? 'transparent' : '#e6f4f1')};
`

export const HamburgerLine3 = styled.div`
    width: 25px;
    height: 4px;
    background-color: #e6f4f1;
    border-radius: 5px;
    transition: all .5s ease-in-out;
    transform: ${({open}) => (open ? 'rotate(-45deg) translateY(-4px)' : 'translateY(3px)')};
`

export const NavMenu = styled.ul`
    display: flex;
    list-style: none;
    margin-right: 50px;

    @media screen and (max-width: 960px) {
        margin-right: 30px;
    }

    @media screen and (max-width: 760px) {
        overflow: hidden;
        flex-direction: column;
        align-items: flex-start;
        width: 60vw;
        max-height: ${({ open }) => (open ? "210px" : "0")};
        transition: max-height 0.2s ease-in;
        position: absolute;
        top: 58px;
        right: 0;
        margin: 0;
        z-index: 11;
        background-color: #1C1A28;
        box-shadow: 1px 9px 5px rgba(0,0,0,.6);
    }

    @media screen and (max-width: 480px){
        width: 90vw;
    }
`

export const NavItem = styled.li`
    display: flex;
    align-items: center;
`

export const RLink = styled(LinkR)`
    font-size: 1.8rem;
    text-decoration: none;
    color: #f7ffff;
    cursor: pointer;
    padding: 12px 25px;
    transition: all 0.2s ease-in;
    font-family: 'Amita', cursive;
    letter-spacing: 1px;

    &:hover {
        background-color: #aca9bb;
        color: #1C1A28;
        font-weight: bold;
    }

    @media screen and (max-width: 960px) {
        font-size: 1.7rem;
        padding: 18px 20px;
    }   

    @media screen and (max-width: 760px) {
        font-size: 1.7rem;
        padding: 10px 20px;
    }   
`

export const SLink = styled(LinkS)`
    font-size: 1.8rem;
    text-decoration: none;
    color: #f7ffff;
    cursor: pointer;
    padding: 12px 25px;
    transition: all 0.2s ease-in;
    font-family: 'Amita', cursive;
    letter-spacing: 1px;

    &:hover {
        background-color: #aca9bb;
        color: #1C1A28;
        font-weight: bold;
    }

    @media screen and (max-width: 960px) {
        font-size: 1.7rem;
        padding: 18px 20px;
    }   

    @media screen and (max-width: 760px) {
        font-size: 1.7rem;
        padding: 10px 20px;
    }    
`

export const HLink = styled(LinkH)`
    font-size: 1.8rem;
    font-weight: normal;
    text-decoration: none;
    color: #f7ffff;
    cursor: pointer;
    padding: 12px 25px;
    transition: all 0.2s ease-in;
    font-family: 'Amita', cursive;
    letter-spacing: 1px;

    &:hover {
        background-color: #aca9bb;
        color: #1C1A28;
        font-weight: bold;
    }

    @media screen and (max-width: 960px) {
        font-size: 1.7rem;
        padding: 18px 20px;
    }   

    @media screen and (max-width: 760px) {
        font-size: 1.7rem;
        padding: 10px 20px;
    }   
`

export const DropdownMenu = styled.div`
    font-size: 1.8rem;
    font-weight: normal;
    text-decoration: none;
    background-color: ${({ activeMenu }) => (activeMenu ? "#aca9bb" : "transparent")};
    color: ${({ activeMenu }) => (activeMenu ? "#00182d" : "#f7ffff")};
    font-weight: ${({ activeMenu }) => (activeMenu ? "bold" : "normal")};
    cursor: pointer;
    padding: 12px 25px;
    transition: all 0.2s ease-in;
    font-family: 'Amita', cursive;
    letter-spacing: 1px;

    &:hover {
        background-color: #aca9bb;
        color: #1C1A28;
        font-weight: bold;
    }

    @media screen and (max-width: 960px) {
        font-size: 1.7rem;
        padding: 18px 20px;
    }   

    @media screen and (max-width: 760px) {
        font-size: 1.7rem;
        padding: 10px 20px;
    }    

    div {
        display: inline-block;
        width: 13px;
        height: 13px;
        border-right: 2px solid #aca9bb;
        border-bottom: 2px solid #aca9bb;
        transform: rotate(45deg) translateY(-5px);

        @media screen and (max-width: 760px) {
            width: 10px;
            height: 10px;
            transform: rotate(-45deg) translateX(5px);
        }
    }
`

export const ContactContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 35px;
    background-color: ${({ scrollNav }) => (scrollNav ? "#0b374e" : "#4c708a")};
    position: absolute;
    bottom: ${({ scrollNav }) => (scrollNav ? "calc(-100vh + 60px)" : "-35px")};
    left: 0;
    transition: all 0.2s ease-in;
`

export const ContactData = styled.div`
    display: flex;
    color: #fff;
    cursor: pointer;
    font-family: 'Amita', cursive;

    p {
        margin-left: 40px;
        display: flex;

        @media screen and (max-width: 960px) {
            margin-left: 30px;
            letter-spacing: .1rem;
        }   

        @media screen and (max-width: 760px) {
            margin-left: 20px;
        }

        @media screen and (max-width: 480px) {
            margin-left: 10px;
        }

        span {
            font-size: 1.8rem;
            margin: auto 8px;
            transition: all 0.2s ease-in;   

            &:hover {
                font-size: 2rem;
            }

            @media screen and (max-width: 960px) {
                font-size: 1.6rem;
            }

            @media screen and (max-width: 960px) {
                font-size: 1.4rem;
            }

            @media screen and (max-width: 480px) {
                font-size: 1rem;
            }
        }
    }
`

export const SocialMediaIcon = styled.div`
    margin-right: 60px;

    @media screen and (max-width: 960px) {
        margin-right: 30px;
    }

    @media screen and (max-width: 760px) {
        margin-right: 20px;
    }

    @media screen and (max-width: 480px) {
        margin-right: 10px;
    }

    a {
        margin-right: 20px;

        @media screen and (max-width: 480px) {
            margin-right: 12px;
        }
    }
`