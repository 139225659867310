import styled from "styled-components";

export const HeroNSection = styled.section`
    position: relative;
    overflow: hidden;
`

export const HeroNContainer = styled.div`
    max-width: 1300px;
`

export const HeroNContent = styled.div``

export const WindowWrapper = styled.div`
    width: 100vw;
    height: 100vh;
`

export const PrevBtnWrapper = styled.div`
    position: absolute;
    top: calc(50vh - 125px);
    left: 3%;
    cursor: pointer;
    // background-color: rgba(0,0,0,.5);
    width: 60px;
    height: 250px;
    background: linear-gradient(0.5turn, transparent, rgba(0,0,0,.7), transparent);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    transition: all .25s ease;

    &:hover .prevBtn {
        opacity: .7;
        transform: scale(1.3) rotate(45deg);
        transition: all .25s ease;
    }

    @media screen and (max-width: 480px){
        left: 0;
        width: 50px;
    }
`

export const Prev = styled.div`
    width: 20px;
    height: 20px;
    opacity: .4;
    border-bottom: 5px solid #fff;
    border-left: 5px solid #fff;
    transform: rotate(45deg);

    @media screen and (max-width: 480px){
        width: 15px;
        height: 15px;
        border-bottom: 4px solid #fff;
        border-left: 4px solid #fff;
    }
`

export const NextBtnWrapper = styled.div`
    position: absolute;
    top: calc(50vh - 125px);
    right: 3%;
    cursor: pointer;
    background: linear-gradient(0.5turn, transparent, rgba(0,0,0,.7), transparent);
    width: 60px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    transition: all .25s ease;

    &:hover .nextBtn {
        opacity: .7;
        transform: scale(1.3) rotate(45deg);
        transition: all .25s ease;
    }

    @media screen and (max-width: 480px){
        right: 0;
        width: 50px;
    }
`

export const Next = styled.div`
    width: 20px;
    height: 20px;
    opacity: .4;
    border-top: 5px solid #fff;
    border-right: 5px solid #fff;
    transform: rotate(45deg);

    @media screen and (max-width: 480px){
        width: 15px;
        height: 15px;
        border-top: 4px solid #fff;
        border-right: 4px solid #fff;
    }
`

export const DotsWrapper = styled.div`
    position: absolute;
    bottom: 5%;
    left: calc(50vw - 515px);
    display: flex;
    background: linear-gradient(0.25turn, transparent, rgba(0,0,0,.8), transparent);
    border-radius: 10px;
    padding: 2px 400px;

    .active {
        opacity: .7;
        transform: scale(1.2);
    }

    .inactive {
        opacity: .4;
    }

    @media screen and (max-width: 480px){
        bottom: 8%;
    }
`

export const Dot = styled.div`
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50%;
    opacity: .4;
    margin: 10px;

    &:hover{
        cursor: pointer;
        opacity: .7;
        transition: all .25s ease;
        transform: scale(1.2);
    }
`

export const ImageSlider = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
`

export const Content = styled.div`
    color: #e6f4f1;
    position: absolute;
    bottom: 90px;
    left: calc(49% - 45vw);
    width: 90vw;
    background: linear-gradient(0.25turn, transparent, rgba(0,0,0,.8), transparent);
    padding-bottom: 10px;

    @media screen and (max-width: 480px){
        bottom: 13vh;
        width: 100%;
        left: 0;
        padding-bottom: 20px;
    }
`

export const TextQuote = styled.p`
    font-size: 2.2rem;
    font-family: 'Amita', cursive;
    letter-spacing: 1px;
    text-align: center;
    position: relative;
    margin-bottom: 2px;
    padding: 5px 50px;

    @media screen and (max-width: 1024px) {
        font-size: 1.6rem;
        letter-spacing: .1rem;
    }

    @media screen and (max-width: 768px) {
        padding: 5px 30px;
    }

    @media screen and (max-width: 480px) {
        font-size: 1.2rem;
        letter-spacing: .05rem;
        padding: 5px;
    }
`

export const Author = styled.span`
    font-size: 2rem;
    position: absolute;
    right: 15%;
    font-family: 'Amita', cursive;
    letter-spacing: 1px;

    @media screen and (max-width: 1024px) {
        font-size: 1.6rem;
    }

    @media screen and (max-width: 480px) {
        font-size: 1.1rem;
        right: 5%;
    }
`