export const DropdownItems = [
    {
        title: 'Wesele',
        path: '/wesele',
    },
    {
        title: 'Sklep / dokumenty',
        path: '/sklep',
    },
    {
        title: 'Okolicznościowe',
        path: '/okolicznosciowe',
    },
    {
        title: 'Szkoły',
        path: '/szkoly',
    }
]